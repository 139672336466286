<template>
  <div class="container mypage withTop">
    <section class="contents myMenu">
      <article class="inner">
        <p>개인설정</p>
        <ul>
          <li>
            <router-link to="/mypage/userInfo">회원정보</router-link>
          </li>
          <li>
            <router-link to="/mypage/changePw">비밀번호변경</router-link>
          </li>
          <li>
            <router-link to="/mypage/farmList">농장관리</router-link>
          </li>
          <li>
            <div class="flexB">
              푸시알림설정
              <el-switch v-model="alarmSwitch" @change="changeAppAlarm">
              </el-switch>
            </div>
          </li>
        </ul>
        <p>서비스 정보</p>
        <ul>
          <li>
            <router-link to="/terms">이용약관</router-link>
          </li>
          <li>
            <router-link to="/privacy">개인정보 처리방침</router-link>
          </li>
        </ul>
        <p>이용안내</p>
        <ul>
          <li>
            <router-link to="/notice"
              >공지사항 <span v-if="total > noticeCount"></span
            ></router-link>
          </li>
          <li>
            <router-link to="/faq">자주 하는 질문</router-link>
          </li>
          <li>
            <a href="http://pf.kakao.com/_ixffVxj/chat" target="_blank"
              >카카오톡 채팅 문의</a
            >
          </li>
        </ul>
        <ul>
          <li>
            <router-link to @click.native="withdraw">회원탈퇴</router-link>
          </li>
          <li>
            <router-link to @click.native="logout">로그아웃</router-link>
          </li>
        </ul>
      </article>
    </section>
  </div>
</template>
<script>
import { createWithdraw, fetchNoticeList, updateAppAlarm } from "@/api/index";
import { mapState } from "vuex";

export default {
  data() {
    return {
      callActive: false,
      alarmSwitch: null,
      total: 0,
      noticeCount: 0,
    };
  },
  computed: {
    ...mapState(["appAlarm"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 4);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "마이페이지");
  },
  mounted() {
    this.getNotice();
    this.alarmSwitch = this.appAlarm;
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getNotice() {
      let params = {
        page: 1,
      };
      this.noticeCount =
        localStorage.getItem("noticeCount") == null
          ? 0
          : localStorage.getItem("noticeCount");
      fetchNoticeList(params).then((res) => {
        if (res.data.status == 200) {
          this.total = res.data.data[0].total;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    changeAppAlarm() {
      let data = {
        appAlarm: this.alarmSwitch,
      };
      updateAppAlarm(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.dispatch("getAccountInfo");
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    withdraw() {
      this.$confirm(
        "회원탈퇴 하시겠습니까? 회원탈퇴 후에는 모든 개인정보가 삭제됩니다.",
        {
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          center: true,
        }
      )
        .then(() => {
          createWithdraw().then((res) => {
            if (res.data.status == 200) {
              this.$store.dispatch("LOGOUT");
            } else {
              this.openAlert(res.data.message);
            }
          });
        })
        .catch(() => {});
    },
    logout() {
      this.$confirm("로그아웃 하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          this.$store.dispatch("LOGOUT");
        })
        .catch(() => {});
    },
  },
};
</script>
